/**
 * @generated SignedSource<<c8b1bba946fb88bc947926c6e2bbe557>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountActivateWrapper_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useAccountActivateWrapper_user">;
  readonly " $fragmentType": "AccountActivateWrapper_user";
};
export type AccountActivateWrapper_user$key = {
  readonly " $data"?: AccountActivateWrapper_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountActivateWrapper_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountActivateWrapper_user",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useAccountActivateWrapper_user",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Company",
          "kind": "LinkedField",
          "name": "company",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyBankAccount",
              "kind": "LinkedField",
              "name": "defaultCompanyBankAccount",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "5d3dc61295011a07c353dcf0979bef6d";

export default node;
