import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined';
import HelpIcon from '@mui/icons-material/Help';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocationOn from '@mui/icons-material/LocationOn';
import PaymentIcon from '@mui/icons-material/Payment';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import SettingsInputHdmiOutlined from '@mui/icons-material/SettingsInputHdmiOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import type { ReactElement } from 'react';
import type { TFunction } from 'react-i18next';
import { IoKeyOutline } from 'react-icons/io5';
import { graphql, readInlineData } from 'react-relay';

import { MODULES } from '@woovi/roles';

import type { menuItems_query$key } from './__generated__/menuItems_query.graphql';
import { filterItems } from './filterItems';
import { charges } from './items/openpix/charges';
import { rewards } from './items/openpix/rewards';
import { transactions } from './items/openpix/transactions';
import { subscriptions } from './items/subscriptions';
import { userAdmin } from './items/user';
import type { Flag } from '../../core/hooks/useUserFlags';
import { routeToMain } from '../../router/utils';
import { usePermission } from '../../security/usePermission';

const menuItemsFragment = graphql`
  fragment menuItems_query on Query @inline {
    companyBankAccounts(first: 3) {
      edges {
        node {
          id
          code
        }
      }
    }
    cashbacks(first: 1) {
      edges {
        node {
          id
        }
      }
    }
    me {
      id
      ...usePermission_user
      ...permissionsUserFragment @relay(mask: false)
      isAdmin
      allRoles
      isResponsibleByCostRevenueCenters
      company {
        projectType
        preferences {
          openpix {
            defaultCompanyBankAccount {
              id
            }
          }
          cashback {
            active
            percentage
          }
          cashbackExclusive {
            active
            percentage
          }
        }
        costRevenueCenters(first: 1) {
          edges {
            node {
              id
            }
          }
        }
        hasCompanyBankAccountVirtual
        hasCompanyBankAccount
        ...subscriptions_company
      }
    }
  }
`;

export type SideBarMenuItemProps = {
  query: menuItems_query$key;
  t: TFunction;
};

type Props = {
  menuItemsFragmentRef: menuItems_query$key;
  flags: Flag[];
  t: TFunction;
};

export type MenuItem = {
  items?: MenuItem[];
  link?: string;
  label: string;
  separator?: boolean;
  icon: ReactElement;
  newChip?: boolean;
  isTemp?: boolean;
};

export default ({ menuItemsFragmentRef, flags, t }: Props): MenuItem[] => {
  const query = readInlineData(menuItemsFragment, menuItemsFragmentRef);
  const { hasRole, hasFeature } = usePermission(query.me);

  const getAccountsLink = (): string => {
    if (hasFeature([MODULES.MULTI_ACCOUNT])) {
      return routeToMain('accounts.pix.list');
    }

    const hasOnlyVirtualAccount =
      !query?.me?.company?.hasCompanyBankAccount &&
      query?.me?.company?.hasCompanyBankAccountVirtual;

    const hasOneCompanyBankAccount =
      query?.companyBankAccounts?.edges?.length === 1;

    const hasDefaultCompanyBankAccount =
      !!query?.me?.company?.preferences?.openpix?.defaultCompanyBankAccount;

    const canGoToDetail =
      hasOnlyVirtualAccount &&
      hasOneCompanyBankAccount &&
      hasDefaultCompanyBankAccount;

    if (canGoToDetail) {
      return routeToMain('companyBank.details.data', {
        id: query?.me?.company?.preferences?.openpix?.defaultCompanyBankAccount
          ?.id,
      });
    }

    return routeToMain('accounts.pix.list');
  };

  const getAreasLink = (): string => {
    if (query?.me?.company?.costRevenueCenters?.edges.length > 0) {
      return routeToMain('areas.list');
    }

    return routeToMain('areas.tutorial');
  };

  const customerLink = 'crm.user.customer.report';

  const getCheckout = (): MenuItem[] => {
    if (!hasFeature([MODULES.CHECKOUT])) {
      return [];
    }

    return [
      {
        label: t('Checkout'),
        icon: <ShoppingCartIcon />,
        link: routeToMain('checkout'),
      },
    ];
  };

  const getCard = (): MenuItem[] => {
    if (!hasFeature([MODULES.CREDIT_CARD])) {
      return [];
    }

    return [
      {
        label: t('Cards'),
        icon: <PaymentIcon />,
        link: routeToMain('card'),
      },
    ];
  };

  const items: MenuItem[] = [
    {
      label: t('Home'),
      icon: <HomeOutlinedIcon />,
      link: routeToMain('home'),
    },
    {
      label: t('Customers'),
      icon: <PersonOutlinedIcon />,
      link: routeToMain(customerLink),
    },
    ...charges({ t, hasRole }),
    ...subscriptions({ t, company: query.me.company }),
    ...transactions({ t, hasRole }),
    ...rewards({ t, hasFeature, query }),
    ...getCheckout(),
    ...getCard(),
    {
      label: t('Stores/Static QRCodes'),
      icon: <QrCode2Icon />,
      link: routeToMain('pixqrcodes'),
    },
    {
      label: t('Withdrawals/Payments'),
      icon: <PaymentIcon />,
      link: routeToMain('payments'),
    },
    {
      label: t('My companies'),
      icon: <BusinessOutlinedIcon />,
      link: routeToMain('partner'),
    },
    {
      label: t('Help/About'),
      icon: <HelpIcon />,
      link: routeToMain('help'),
    },
    {
      label: t('Tutorial'),
      icon: <ComputerOutlinedIcon />,
      link: routeToMain('tutorial'),
    },
    {
      label: t('Accounts'),
      icon: <AccountBalanceWalletOutlinedIcon />,
      link: getAccountsLink(),
    },
    {
      label: t('Areas/Stores'),
      icon: <LocationOn />,
      link: getAreasLink(),
    },
    ...userAdmin({ query, t }),
    {
      label: t('Users/Permissions'),
      icon: <IoKeyOutline />,
      link: routeToMain('permissions.users.list'),
    },
    {
      label: t('API/Plugins'),
      icon: <SettingsInputHdmiOutlined />,
      link: routeToMain('applications.tutorial'),
    },
    {
      label: t('Settings'),
      icon: <SettingsOutlinedIcon />,
      link: routeToMain('settings'),
    },
  ];

  const filtered = filterItems(items, query);

  return filtered;
};
