export const pagarmeAssertMockCreditCardTokenize = async (
  body: Record<string, unknown>,
) => {
  if (typeof jest === 'undefined') {
    return;
  }

  const fetchMock = require('jest-fetch-mock');

  fetchMock.mockResponseOnce(JSON.stringify(body));
};
