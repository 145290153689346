import * as Sentry from '@sentry/browser';

import { shouldUseSentry } from '@woovi/shared';

export function logOut() {
  const getHref = () => {
    const location = window.location;

    if (
      !location.pathname ||
      location.pathname === '/' ||
      location.pathname.includes('logout')
    ) {
      window.location.href = '/login?invalidSession=true';

      return;
    }

    const urlParams = new URLSearchParams();

    urlParams.set('next', location.pathname + location.search);

    window.location.href = `/login?${urlParams.toString()}&invalidSession=true`;
  };

  if (shouldUseSentry) {
    Sentry.getCurrentScope()?.setUser(null);
  }

  getHref();
}

export function tokenLogOut() {
  logOut();
}
