/**
 * @generated SignedSource<<028efa73fdc865530517a15b0fa15447>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CashSound_user$data = {
  readonly notificationsSettings: ReadonlyArray<string>;
  readonly " $fragmentType": "CashSound_user";
};
export type CashSound_user$key = {
  readonly " $data"?: CashSound_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"CashSound_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CashSound_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notificationsSettings",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "53468661e852aa993546fded568a4ba4";

export default node;
