import SearchIcon from '@mui/icons-material/Search';
import type { ExtendButtonBase, IconButtonTypeMap, Theme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { ChangeEventHandler, KeyboardEvent, KeyboardEventHandler, ReactNode } from 'react';
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

const Search = styled('div')<{
  fullWidth: boolean;
  variant: GlobalSearchFieldVariants;
}>(({ theme, fullWidth, variant }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  position: 'relative',
  height: '36px',
  borderRadius: theme.shape.borderRadius,
  color:
    variant === 'outline'
      ? theme.palette.primary.main
      : theme.palette.common.white,
  backgroundColor:
    variant === 'outline' ? theme.palette.common.white : 'transparent',
  transition: theme.transitions.create('background-color'),
  ...(fullWidth ? { width: '100%' } : {}),
  '&:has(.MuiInputBase-input:focus), &:has(.MuiInputBase-input:not(:placeholder-shown))':
    {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.tertiary.main,
    },
  '&:has(.MuiInputBase-input:focus) .MuiButtonBase-root, &:has(.MuiInputBase-input:not(:placeholder-shown)) .MuiButtonBase-root':
    {
      color: theme.palette.primary.main,
    },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    flexDirection: 'row',
    backgroundColor:
      variant === 'outline'
        ? theme.palette.common.white
        : alpha(theme.palette.common.white, 0.25),
  },
}));

const SearchIconWrapper = styled<
  ExtendButtonBase<
    IconButtonTypeMap<{ variant: GlobalSearchFieldVariants }, 'button'>
  >
>(IconButton)(({ theme, variant }) => ({
  color:
    variant === 'outline'
      ? theme.palette.primary.main
      : theme.palette.common.white,
  padding: theme.spacing(0, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('sm')]: {
    cursor: 'default',
  },
}));

const StyledInputBase = styled(InputBase)(({ theme, fullWidth }) => ({
  color:
    theme.palette.mode === 'dark' ? theme.palette.tertiary.main : 'inherit',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  '& .MuiInputBase-input': {
    transition: theme.transitions.create('create'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:not(:placeholder-shown), &:focus': {
        width: fullWidth ? '100%' : '20ch',
      },
    },
  },
}));

type GlobalSearchFieldVariants = 'primary' | 'outline';

type GlobalSearchFieldProps = {
  onDebounceApplied?: (
    value: string,
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => unknown;
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: string;
  fullWidth?: boolean;
  disableMobile?: boolean;
  variant: GlobalSearchFieldVariants;
};

const GlobalSearchField = (
  {
    onDebounceApplied,
    onChange,
    value,
    fullWidth = false,
    disableMobile = false,
    variant = 'primary',
  }: GlobalSearchFieldProps,
): ReactNode => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
  const inputRef = useRef();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);

    if (
      inputRef.current !== document.activeElement &&
      (isDesktop || disableMobile)
    ) {
      inputRef.current.focus();
    }
  };

  const handleBlur = () => {
    if (!value) {
      setOpen(false);
    }
  };

  const handleDebounceApplied = useDebouncedCallback((e) => {
    if (onDebounceApplied && value) {
      onDebounceApplied(value, e);
    }
  }, 3000);

  const handleOnKeyUp: KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    if (e.key === 'Enter') {
      handleDebounceApplied.cancel();
    } else {
      handleDebounceApplied(e);
    }
  };

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange(e);
  };

  const isDisplayed = open || isDesktop || disableMobile;

  return (
    <Search fullWidth={fullWidth} variant={variant}>
      <SearchIconWrapper variant={variant} onClick={handleClick} disableRipple>
        <SearchIcon sx={{ color: 'inherit' }} />
      </SearchIconWrapper>
      {isDisplayed && (
        <StyledInputBase
          name='search'
          inputRef={inputRef}
          type='search'
          placeholder={`${t('Search')}...`}
          autoFocus={open}
          fullWidth={fullWidth}
          variant={variant}
          inputProps={{
            'aria-label': 'search',
            maxLength: 256,
            'data-testid': 'GlobalSearchField',
          }}
          value={value}
          onChange={handleOnChange}
          onBlur={handleBlur}
          onKeyUp={handleOnKeyUp}
        />
      )}
    </Search>
  );
};

export default GlobalSearchField;
