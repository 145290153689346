/**
 * @generated SignedSource<<029833199f3edf7461ada1a460bca00a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type usePermission_user$data = {
  readonly allRoles: ReadonlyArray<string | null | undefined>;
  readonly company: {
    readonly features: ReadonlyArray<string | null | undefined> | null | undefined;
    readonly projectType: string | null | undefined;
  } | null | undefined;
  readonly isAdmin: boolean | null | undefined;
  readonly " $fragmentType": "usePermission_user";
};
export type usePermission_user$key = {
  readonly " $data"?: usePermission_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"usePermission_user">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "usePermission_user"
};

(node as any).hash = "4863a59d2b17ba9589d6acb3e35ff718";

export default node;
