import TrendingUpIcon from '@mui/icons-material/TrendingUpOutlined';

import { ADMIN_ROLE, ROLES } from '@woovi/roles';

import { routeToMain } from '../../../../router/utils';

export const transactions = ({ t, hasRole }) => {
  const canSeeReport = hasRole([
    ADMIN_ROLE,
    ROLES.OPEN_PIX.ADMIN.ALL,
    ROLES.OPEN_PIX.TRANSACTIONS,
    ROLES.OPEN_PIX.REFUND,
    ROLES.OPEN_PIX.REPORT,
  ]);

  const transactionRoute = {
    label: t('Statement'),
    icon: <TrendingUpIcon />,
  };

  if (canSeeReport) {
    return [{ ...transactionRoute, link: routeToMain('transaction.report') }];
  }

  return [
    {
      ...transactionRoute,
      link: routeToMain('transaction.list'),
    },
  ];
};
