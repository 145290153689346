/**
 * @generated SignedSource<<7f09eac0603e742d9f0b6fca207ab0ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useUserRequiredFields_userMaster$data = {
  readonly requiredFields: ReadonlyArray<string | null | undefined> | null | undefined;
  readonly " $fragmentType": "useUserRequiredFields_userMaster";
};
export type useUserRequiredFields_userMaster$key = {
  readonly " $data"?: useUserRequiredFields_userMaster$data;
  readonly " $fragmentSpreads": FragmentRefs<"useUserRequiredFields_userMaster">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useUserRequiredFields_userMaster"
};

(node as any).hash = "b6e91db2daf3d9035ad0a2b842936917";

export default node;
