/**
 * @generated SignedSource<<fe7744a31a8e6d4056bff0f9f885368d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserMenuAccountItem_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FeatureTemp_user">;
  readonly " $fragmentType": "UserMenuAccountItem_user";
};
export type UserMenuAccountItem_user$key = {
  readonly " $data"?: UserMenuAccountItem_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserMenuAccountItem_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserMenuAccountItem_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeatureTemp_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "5b0189d148ad99c39d2a7567d2bd77b2";

export default node;
