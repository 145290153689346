/**
 * @generated SignedSource<<4d4a70113b19615481e554561c8c96ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useProviderFeatures_account$data = {
  readonly providerFeatures: ReadonlyArray<string>;
  readonly " $fragmentType": "useProviderFeatures_account";
};
export type useProviderFeatures_account$key = {
  readonly " $data"?: useProviderFeatures_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"useProviderFeatures_account">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useProviderFeatures_account"
};

(node as any).hash = "7a08e328816b954a6678f40c2cd98136";

export default node;
