import type { FunctionComponent, ReactNode } from 'react';
import { Suspense } from 'react';

import { Loading, ErrorViewBoundary } from '@woovi/ui';

import type { FetchKeyProp } from './FetchKeyProp';

type Config = {
  loadingView?: NonNullable<ReactNode> | null;
};

export const withRelayBoundary = <T extends FetchKeyProp>(
  Component: FunctionComponent<T>,
  config: Config = {},
): FunctionComponent<Omit<T, 'fetchKey'>> => {
  const { loadingView = <Loading fullScreen={true} /> } = config;

  const QueryRendererWrapper = (props): ReactNode => {
    return (
      <ErrorViewBoundary>
        {({ fetchKey }) => (
          <Suspense fallback={loadingView}>
            <Component {...props} fetchKey={fetchKey} />
          </Suspense>
        )}
      </ErrorViewBoundary>
    );
  };

  QueryRendererWrapper.displayName = `withRelayBoundary(${
    Component.displayName || Component.name
  })`;

  return QueryRendererWrapper;
};
