/**
pnpm jest packages/service-console/src/modules/company/__tests__/CompanyConsoleQueries.spec.ts \
packages/service-console/src/modules/permissions/role/__tests__/RoleList.spec.ts \
packages/service-console/src/modules/company/mutations/console/__tests__/CompanyAddMutation.spec.ts \
packages/service-console/src/modules/company/mutations/console/__tests__/CompanyAddMutationWallet.spec.ts -u

**/

export enum MODULES {
  OPEN_PIX = 'OPEN_PIX',
  TEMP = 'TEMP',
  BETA = 'BETA',
  API = 'API',
  OPENPIX_ADVANCED = 'OPENPIX_ADVANCED',
  CASHBACK = 'CASHBACK',
  PARTNER = 'PARTNER',
  ADJUST = 'ADJUST',
  PERMISSIONS = 'PERMISSIONS',
  CORE = 'CORE',
  SUPPORT = 'SUPPORT',
  PLANNING = 'PLANNING',
  DISCOUNT = 'DISCOUNT',
  MULTI_ACCOUNT = 'MULTI_ACCOUNT',
  PIX_OUT = 'PIX_OUT',
  PIX_CREDIARY = 'PIX_CREDIARY',
  DEMO_EVENT = 'DEMO_EVENT',
  SPLIT_PAYMENT = 'SPLIT_PAYMENT',
  SPLIT_SUB_ACCOUNT = 'SPLIT_SUB_ACCOUNT',
  SUBACCOUNT_BLOCK_BALANCE = 'SUBACCOUNT_BLOCK_BALANCE',
  PIX_CREDIT = 'PIX_CREDIT',
  CREDIT_CARD = 'CREDIT_CARD',
  CHARGE_INTENT = 'CHARGE_INTENT',
  SPLIT_PARTNER = 'SPLIT_PARTNER',
  PIXOUT_APPROVE_API = 'PIXOUT_APPROVE_API',
  ROLLOUT_NEW_CHARGE_POOLING = 'ROLLOUT_NEW_CHARGE_POOLING',
  ITP = 'ITP',
  ITP_RECIPIENT = 'ITP_RECIPIENT',
  ROLLOUT_NEW_REFUND = 'ROLLOUT_NEW_REFUND',
  ROLLOUT_NEW_CHARGE_COMPLETED = 'ROLLOUT_NEW_CHARGE_COMPLETED',
  ROLLOUT_SPLIT_PAYMENT_PROCESS_TRANSACTION = 'ROLLOUT_SPLIT_PAYMENT_PROCESS_TRANSACTION',
  PAY_BILLS = 'PAY_BILLS',
  CHECKOUT = 'CHECKOUT',
  FIDUCIA_MIGRATED = 'FIDUCIA_MIGRATED',
  NEW_PLATFORM_PLANS = 'NEW_PLATFORM_PLANS',
  DEFAULT_TIMEZONE = 'DEFAULT_TIMEZONE',
  PARTNER_WALLET = 'PARTNER_WALLET',
  ROLLOUT_NEW_CHARGE_CREATE = 'ROLLOUT_NEW_CHARGE_CREATE',
  PAY_PIX_KEY_DIRECT = 'PAY_PIX_KEY_DIRECT',
  BOLETO = 'BOLETO',
  SUBSCRIPTION_PLAN = 'SUBSCRIPTION_PLAN',
}

export const ROLES = {
  ADMIN: {
    ALL: {
      ALL: 'ADMIN',
    },
  },
  ADJUST: {
    ALL: 'ADJUST::ALL',
  },
  CORE: {
    ALL: 'CORE::ALL',
    USER: {
      INVITE: 'CORE::USER::INVITE',
      MANAGE_TEAM: 'CORE::USER::MANAGE_TEAM',
    },
    PREFERENCES: {
      CHANGE_MANAGER: 'CORE::PREFERENCES::CHANGE_MANAGER',
      CHANGE_NAME: 'CORE::PREFERENCES::CHANGE_NAME',
      CHANGE_PASSWORD: 'CORE::PREFERENCES::CHANGE_PASSWORD',
      CHANGE_JOB_TITLE: 'CORE::PREFERENCES::CHANGE_JOB_TITLE',
      CHANGE_NICKNAME: 'CORE::PREFERENCES::CHANGE_NICKNAME',
      CHANGE_TAXID: 'CORE::PREFERENCES::CHANGE_TAXID',
      CHANGE_EMAIL: 'CORE::PREFERENCES::CHANGE_EMAIL',
    },
    APPLICATION: 'CORE::APPLICATION',
    LOGIN: 'CORE::LOGIN',
  },
  PLANNING: {
    ALL: 'PLANNING::ALL',
    COST_REVENUE_CENTER: { ALL: 'PLANNING::COST_REVENUE_CENTER::ALL' },
  },
  PERMISSIONS: {
    ALL: 'PERMISSIONS::ALL',
  },
  SUPPORT: { ALL: 'SUPPORT::ALL' },
  OPEN_PIX: {
    ALL: 'OPEN_PIX::ALL',
    USER: { ALL: 'OPEN_PIX::USER::ALL' },
    ADMIN: { ALL: 'OPEN_PIX::ADMIN::ALL' },
    CUSTOMERS: 'OPEN_PIX::CUSTOMERS',
    CUSTOMER_EXPORT: 'OPEN_PIX::CUSTOMER_EXPORT',
    CHARGES: 'OPEN_PIX::CHARGES',
    CHARGE_VIEW: 'OPEN_PIX::CHARGE_VIEW',
    CHARGE_CREATE: 'OPEN_PIX::CHARGE_CREATE',
    CHARGE_EXPORT: 'OPEN_PIX::CHARGE_EXPORT',
    CHARGE_REPORT: 'OPEN_PIX::CHARGE_REPORT',
    CHARGE_DELETE: 'OPEN_PIX::CHARGE_DELETE',
    CHARGE_SEE_MINE: 'OPEN_PIX::CHARGE_SEE_MINE',
    CHARGE_SEE_ALL: 'OPEN_PIX::CHARGE_SEE_ALL',
    PAYMENTS: 'OPEN_PIX::PAYMENTS',
    QRCODES: 'OPEN_PIX::QRCODES',
    REFUND: 'OPEN_PIX::REFUND',
    REPORT: 'OPEN_PIX::REPORT',
    SELLER: 'OPEN_PIX::SELLER',
    TRANSACTIONS: 'OPEN_PIX::TRANSACTIONS',
    TRANSACTIONS_EXPORT: 'OPEN_PIX::TRANSACTIONS_EXPORT',
    ACCOUNT_WITHDRAW: 'OPEN_PIX::ACCOUNT_WITHDRAW',
    ACCOUNT_STATEMENT: 'OPEN_PIX::ACCOUNT_STATEMENT',
    ACCOUNT_BALANCE: 'OPEN_PIX::ACCOUNT_BALANCE',
    API_WEBHOOK: 'OPEN_PIX::API_WEBHOOK',
  },
  OPENPIX_ADVANCED: {
    ALL: 'OPENPIX_ADVANCED::ALL',
    ADMIN: { ALL: 'OPENPIX_ADVANCED::ADMIN::ALL' },
  },
  API: { ALL: 'API::ALL', ADMIN: { ALL: 'API::ADMIN::ALL' } },
  PIX_OUT: {
    BILL: {
      REQUEST: 'PIX_OUT::BILL::REQUEST',
      VIEW: 'PIX_OUT::BILL::VIEW',
      APPROVE: 'PIX_OUT::BILL::APPROVE',
    },
  },
  PARTNER: {
    ALL: 'PARTNER::ALL',
  },
};

// tests to update
// jest packages/service-main/src/modules/company/defaults/__tests__/CompanyDefaultRoleGroup.spec.ts
// jest packages/service-main/src/modules/company/mutations/console/__tests__/CompanyDefaults.spec.ts
// ROLES ENABLED ON DEFAULT COMPANY ROLE GROUP BASED ON MODULES
export const MODULE_DEFAULT_ROLES = {
  [MODULES.PLANNING]: [],
  [MODULES.PERMISSIONS]: [],
  [MODULES.TEMP]: [],
  [MODULES.SUPPORT]: [ROLES.SUPPORT.ALL],
  [MODULES.CORE]: [
    ROLES.CORE.USER.MANAGE_TEAM,
    ROLES.CORE.USER.INVITE,
    ROLES.CORE.PREFERENCES.CHANGE_NAME,
    ROLES.CORE.PREFERENCES.CHANGE_MANAGER,
    ROLES.CORE.PREFERENCES.CHANGE_JOB_TITLE,
    ROLES.CORE.PREFERENCES.CHANGE_PASSWORD,
    ROLES.CORE.PREFERENCES.CHANGE_NICKNAME,
    ROLES.CORE.PREFERENCES.CHANGE_TAXID,
    ROLES.CORE.PREFERENCES.CHANGE_EMAIL,
  ],
  [MODULES.OPEN_PIX]: [
    ROLES.OPEN_PIX.USER.ALL,
    ROLES.OPEN_PIX.REPORT,
    ROLES.OPEN_PIX.CHARGE_SEE_ALL,
  ],
  [MODULES.OPENPIX_ADVANCED]: [],
  [MODULES.API]: [],
} as const;

export const ADMIN_ROLE = ROLES.ADMIN.ALL.ALL;

export const PRE_REGISTER_MODULES = [MODULES.PLANNING, MODULES.SUPPORT];

export const OPENPIX_MODULES = [
  MODULES.OPEN_PIX,
  MODULES.ADJUST,
  MODULES.PERMISSIONS,
  MODULES.SUPPORT,
  MODULES.CASHBACK,
  MODULES.CHARGE_INTENT,
  MODULES.PIX_CREDIARY,
  MODULES.CHECKOUT,
  MODULES.NEW_PLATFORM_PLANS,
];

export const COMPANY_PROJECT_TYPES_ENUM = {
  OPEN_PIX: 'OPEN_PIX',
  WOOVI: 'WOOVI',
} as const;

export const flatten = (arr: Array<string>): Array<string> =>
  arr.reduce(
    (flat, toFlatten) =>
      flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten),
    [],
  );

export const formatRole = (
  obj: Record<string, string>,
): Record<string, string> =>
  Object.values(obj).map((value) =>
    typeof value === 'string' ? value : formatRole(value),
  );

export const rolesAsArray = (): string[] => flatten(formatRole(ROLES));

// add more console roles
export const CONSOLE_ROLES = {
  ADMIN_ROLE,
  ADMIN: {
    ALL: {
      ALL: 'ADMIN',
    },
  },
  COMPANY_ALL: 'COMPANY::ALL',

  USER_LOGIN: 'USER::LOGIN',
  USER_ALL: 'USER::ALL',

  LEAD_ALL: 'LEAD::ALL',

  ACCOUNT_REGISTER_APPROVE: 'ACCOUNT_REGISTER::APPROVE',
  ACCOUNT_REGISTER_EDIT: 'ACCOUNT_REGISTER:EDIT',
  ACCOUNT_REGISTER_DELETE: 'ACCOUNT_REGISTER::DELETE',
  ACCOUNT_REGISTER_VERIFY: 'ACCOUNT_REGISTER::VERIFY',
  ACCOUNT_REGISTER_ALL: 'ACCOUNT_REGISTER::ALL',

  ACCOUNT_BLOCK_WITHDRAW: 'ACCOUNT::BLOCK_WITHDRAW',
  ACCOUNT_EDIT_FEE: 'ACCOUNT::EDIT::FEE',

  TRUST_LIST_ALL: 'TRUST_LIST::ALL',

  // think and rethink this
  DEVELOPERS: 'DEVELOPERS', // this should be a role group instead, but admin role does not have a role group
  CUSTOMER_SUCCESS: 'CUSTOMER_SUCCESS',
  SALES: 'SALES',
};
