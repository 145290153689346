import type { CardProcessingProvider } from './CardProcessingProvider';
import { getCardProcessingProviders } from './CardProcessingRegister';

export const getCardProcessingProviderByName = (
  name: string,
): CardProcessingProvider | null => {
  const providers = getCardProcessingProviders();

  const provider = providers.find((p) => p.name === name);

  if (provider) {
    return provider;
  }

  // eslint-disable-next-line
  console.log('provider not found');

  return null;
};
