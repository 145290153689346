/**
 * @generated SignedSource<<94dd89f62fefffdd22dae1ac3f7e714f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useCompanyRequiredFields_company$data = {
  readonly requiredFields: ReadonlyArray<string | null | undefined> | null | undefined;
  readonly " $fragmentType": "useCompanyRequiredFields_company";
};
export type useCompanyRequiredFields_company$key = {
  readonly " $data"?: useCompanyRequiredFields_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCompanyRequiredFields_company">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useCompanyRequiredFields_company"
};

(node as any).hash = "0925d0004656ec9aa6e5254be37a52f0";

export default node;
