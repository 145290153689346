/**
 * @generated SignedSource<<26a784b3842e2e45a5df4d92b42e3862>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserMenuConfirmDefaultAccountModal_account$data = {
  readonly id: string;
  readonly " $fragmentType": "UserMenuConfirmDefaultAccountModal_account";
};
export type UserMenuConfirmDefaultAccountModal_account$key = {
  readonly " $data"?: UserMenuConfirmDefaultAccountModal_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserMenuConfirmDefaultAccountModal_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserMenuConfirmDefaultAccountModal_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "CompanyBankAccount",
  "abstractKey": null
};

(node as any).hash = "05fbcb3685ab759a0f7ca0e501ec9cd3";

export default node;
