/**
 * @generated SignedSource<<72b80b547390057eeb2d08d5ee3b2e83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminApp_query$data = {
  readonly appEnv: string | null | undefined;
  readonly me: {
    readonly _id: string;
    readonly allRoles: ReadonlyArray<string | null | undefined>;
    readonly company: {
      readonly _id: string;
      readonly features: ReadonlyArray<string | null | undefined> | null | undefined;
      readonly lang: string | null | undefined;
      readonly name: string | null | undefined;
      readonly preferences: {
        readonly core: {
          readonly agreementTerm: {
            readonly active: boolean;
          };
        };
      };
      readonly projectType: string | null | undefined;
      readonly timezone: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"useCompanyRequiredFields_company">;
    } | null | undefined;
    readonly hasAcceptAgreementTerm: boolean;
    readonly id: string;
    readonly isAdmin: boolean | null | undefined;
    readonly lang: string | null | undefined;
    readonly name: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"CashSound_user" | "FeatureTemp_user" | "useCrisp_user" | "usePermission_user">;
  } | null | undefined;
  readonly meMaster: {
    readonly " $fragmentSpreads": FragmentRefs<"useShouldChangePassword_userMaster" | "useUserRequiredFields_userMaster">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"Header_query" | "Sidebar_query">;
  readonly " $fragmentType": "AdminApp_query";
};
export type AdminApp_query$key = {
  readonly " $data"?: AdminApp_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminApp_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "features",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectType",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lang",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "requiredFields",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allRoles",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminApp_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Company",
          "kind": "LinkedField",
          "name": "company",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timezone",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyPreferences",
              "kind": "LinkedField",
              "name": "preferences",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CompanyCorePreferences",
                  "kind": "LinkedField",
                  "name": "core",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CompanyAgreementTermPreferences",
                      "kind": "LinkedField",
                      "name": "agreementTerm",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "active",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "useCompanyRequiredFields_company",
              "selections": (v5/*: any*/),
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        },
        (v6/*: any*/),
        (v7/*: any*/),
        {
          "kind": "InlineDataFragmentSpread",
          "name": "useCrisp_user",
          "selections": [
            (v8/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Company",
              "kind": "LinkedField",
              "name": "company",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameFriendly",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phoneNumbers",
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FeatureTemp_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CashSound_user"
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "usePermission_user",
          "selections": [
            (v6/*: any*/),
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Company",
              "kind": "LinkedField",
              "name": "company",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        (v2/*: any*/),
        (v8/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasAcceptAgreementTerm",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserMaster",
      "kind": "LinkedField",
      "name": "meMaster",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "useShouldChangePassword_userMaster",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shouldChangePassword",
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "useUserRequiredFields_userMaster",
          "selections": (v5/*: any*/),
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Header_query"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Sidebar_query"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appEnv",
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "f2b13a3649720c882e2ae1c37d190e9f";

export default node;
