/**
 * @generated SignedSource<<f2b4ba9647732f53edefb6fd53550f4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CompanyBankAccountSetDefaultInput = {
  clientMutationId?: string | null | undefined;
  id: string;
};
export type CompanyBankAccountSetDefaultMutation$variables = {
  input: CompanyBankAccountSetDefaultInput;
};
export type CompanyBankAccountSetDefaultMutation$data = {
  readonly CompanyBankAccountSetDefault: {
    readonly company: {
      readonly defaultCompanyBankAccount: {
        readonly code: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly companyBankAccount: {
      readonly balance: number | null | undefined;
      readonly code: string | null | undefined;
      readonly hasBalance: boolean;
      readonly id: string;
      readonly isDefault: boolean;
    } | null | undefined;
    readonly error: string | null | undefined;
    readonly success: string | null | undefined;
  } | null | undefined;
};
export type CompanyBankAccountSetDefaultMutation = {
  response: CompanyBankAccountSetDefaultMutation$data;
  variables: CompanyBankAccountSetDefaultMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "CompanyBankAccount",
  "kind": "LinkedField",
  "name": "companyBankAccount",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDefault",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasBalance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "balance",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompanyBankAccountSetDefaultMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompanyBankAccountSetDefaultPayload",
        "kind": "LinkedField",
        "name": "CompanyBankAccountSetDefault",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyBankAccount",
                "kind": "LinkedField",
                "name": "defaultCompanyBankAccount",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompanyBankAccountSetDefaultMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompanyBankAccountSetDefaultPayload",
        "kind": "LinkedField",
        "name": "CompanyBankAccountSetDefault",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyBankAccount",
                "kind": "LinkedField",
                "name": "defaultCompanyBankAccount",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3f3d9c44254aca401169f59a071150ab",
    "id": null,
    "metadata": {},
    "name": "CompanyBankAccountSetDefaultMutation",
    "operationKind": "mutation",
    "text": "mutation CompanyBankAccountSetDefaultMutation(\n  $input: CompanyBankAccountSetDefaultInput!\n) {\n  CompanyBankAccountSetDefault(input: $input) {\n    success\n    error\n    companyBankAccount {\n      id\n      code\n      isDefault\n      hasBalance\n      balance\n    }\n    company {\n      defaultCompanyBankAccount {\n        code\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3cb438a5babc8fafdcef353edd02a50f";

export default node;
