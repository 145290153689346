import { Box } from '@mui/material';
import type { BoxProps } from '@mui/material';
import { forwardRef } from 'react';
import type { ReactNode } from 'react';

type MuiFlexProps = {
  children: ReactNode;
  tabIndex?: number;
  sx?: BoxProps['sx'];
  'data-testid'?: string;
};

const BoxFlex = forwardRef(
  ({ children, sx, tabIndex, ...props }: MuiFlexProps, ref) => (
    // @todo: Fix the order of styles here, to imply that every BoxFlex will have flex display.
    (<Box
      ref={ref}
      tabIndex={tabIndex}
      data-testid={props['data-testid']}
      sx={{ display: 'flex', ...sx }}
    >
      {children}
    </Box>)
  ),
);

export default BoxFlex;
