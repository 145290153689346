import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

import type { ReactNode } from 'react';

type SwipeableDialogTitleProps = {
  children: React.ReactNode;
};

const DIALOG_TITLE_NAME = 'SwipeableDialogTitle';

export const SwipeableDialogTitle = (props: SwipeableDialogTitleProps): ReactNode => {
  const { children } = props;

  return <DialogTitle id='dialog-title'>{children}</DialogTitle>;
};

SwipeableDialogTitle.displayName = DIALOG_TITLE_NAME;
