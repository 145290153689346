/**
 * @generated SignedSource<<fec546a8b5d7961911e942cd87cb3694>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuickActionsModal_me$data = {
  readonly userMaster: {
    readonly isTOTPActive: boolean;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"AccountActivateWrapper_user" | "usePermission_user">;
  readonly " $fragmentType": "QuickActionsModal_me";
};
export type QuickActionsModal_me$key = {
  readonly " $data"?: QuickActionsModal_me$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuickActionsModal_me">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuickActionsModal_me",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "usePermission_user",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAdmin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allRoles",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Company",
          "kind": "LinkedField",
          "name": "company",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "features",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "projectType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccountActivateWrapper_user"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserMaster",
      "kind": "LinkedField",
      "name": "userMaster",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isTOTPActive",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "3972ef1162ec3e964d1862aaa007aff1";

export default node;
