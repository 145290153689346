import { graphql, readInlineData } from 'react-relay';

import { MODULES } from '@woovi/roles';

import type { usePermission_user$key } from './__generated__/usePermission_user.graphql';
import type {
  RequiredFeaturesType,
  RequiredProjectsTypes,
  RequiredRolesType,
} from './permissions';
import { hasFeature, hasPermission, hasRole } from './permissions';

export const usePermission = (userRef: usePermission_user$key) => {
  const user = readInlineData<usePermission_user$key>(
    graphql`
      fragment usePermission_user on User @inline {
        isAdmin
        allRoles
        company {
          features
          projectType
        }
      }
    `,
    userRef,
  );

  return {
    hasFeature: (requiredFeatures: RequiredFeaturesType) =>
      hasFeature(user, requiredFeatures),
    hasRole: (requiredRoles: RequiredRolesType) => hasRole(user, requiredRoles),
    hasPermission: (
      requiredFeatures?: RequiredFeaturesType,
      requiredRoles?: RequiredRolesType,
      requiredProjectsTypes: RequiredProjectsTypes = [],
    ) =>
      hasPermission(
        user,
        requiredFeatures,
        requiredRoles,
        requiredProjectsTypes,
      ),
    hasTemp: user ? hasFeature(user, [MODULES.TEMP]) : false,
  };
};
