/**
 * @generated SignedSource<<5add5a5b87e5607b795ae2e1ea4b5fd3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useAuthenticated_user$data = {
  readonly allRoles: ReadonlyArray<string | null | undefined>;
  readonly company: {
    readonly features: ReadonlyArray<string | null | undefined> | null | undefined;
    readonly projectType: string | null | undefined;
  } | null | undefined;
  readonly isAdmin: boolean | null | undefined;
  readonly " $fragmentType": "useAuthenticated_user";
};
export type useAuthenticated_user$key = {
  readonly " $data"?: useAuthenticated_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAuthenticated_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useAuthenticated_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "features",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAdmin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allRoles",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "ce36ff5a163eea4c76367d23dc84c3cb";

export default node;
