import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { ReactNode } from 'react';
import { useFragment, graphql } from 'react-relay';
import styled from 'styled-components';

import { MODULES } from '@woovi/roles';
import { BoxFlex } from '@woovi/ui';

import type { Header_query$key } from './__generated__/Header_query.graphql';
import AccountRegisterRequestedDocumentsHeaderBanner from './AccountRegisterRequestedDocumentsHeaderBanner';
import { ElevationScroll } from './ElevationScroll';
import HeaderBanner from './HeaderBanner';
import HeaderSearchField from './HeaderSearchField';
import { MenuButton } from './MenuButton';
import { MenuRightIcons } from './MenuRightIcons';
import { usePermission } from '../../security/usePermission';

const StyledAppBar = styled(AppBar)`
  background: ${(props) =>
    `linear-gradient(to right,${props.color},${props.theme.palette.primary.dark})`};
  color: ${(props) => props.theme.palette.primary.contrastText};
  z-index: 900;
`;

const StyledToolbar = styled(Toolbar)`
  height: 60px;
  min-height: 0;
`;

type Props = {
  query: Header_query$key;
};
const Header = (props: Props): ReactNode => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const query = useFragment<Header_query$key>(
    graphql`
      fragment Header_query on Query {
        ...MenuRightIcons_query
        me {
          ...HeaderBanner_me
          ...usePermission_user
          ...AccountRegisterRequestedDocumentsHeaderBanner_me
        }
        appEnv
      }
    `,
    props.query,
  );

  const { hasFeature } = usePermission(query.me);
  const hasTemp = hasFeature([MODULES.TEMP]);

  const { me, appEnv } = query;

  if (!me) {
    return null;
  }

  const getHeaderColor = (): string => {
    if (appEnv === 'development') {
      return 'secondary';
    }

    if (appEnv === 'staging') {
      if (!hasTemp) {
        return 'primary';
      }

      return 'tertiary';
    }

    return 'primary';
  };

  return (
    <>
      <ElevationScroll>
        <StyledAppBar position='sticky' color={getHeaderColor()}>
          <StyledToolbar disableGutters>
            <MenuButton />
            <BoxFlex
              sx={{
                flexGrow: 1,
                flexDirection: isMobile ? 'row-reverse' : 'row',
              }}
            >
              <HeaderSearchField />
            </BoxFlex>
            <MenuRightIcons query={query} />
          </StyledToolbar>
        </StyledAppBar>
      </ElevationScroll>
      <HeaderBanner me={me} />
      <AccountRegisterRequestedDocumentsHeaderBanner me={me} />
    </>
  );
};

export default Header;
