/**
 * @generated SignedSource<<21348ebc0a1e008caca5153bf38c9632>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserMenuAuthItem_user$data = {
  readonly company: {
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentType": "UserMenuAuthItem_user";
};
export type UserMenuAuthItem_user$key = {
  readonly " $data"?: UserMenuAuthItem_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserMenuAuthItem_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserMenuAuthItem_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "318f8a9acecce7c1cdd3705869278cff";

export default node;
