/**
 * @generated SignedSource<<a8ced806644b5a5103ef7e40a545810f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AddressQuery$variables = {
  zipcode: string;
};
export type AddressQuery$data = {
  readonly address: {
    readonly city: string | null | undefined;
    readonly neighborhood: string | null | undefined;
    readonly state: string | null | undefined;
    readonly street: string | null | undefined;
  } | null | undefined;
};
export type AddressQuery = {
  response: AddressQuery$data;
  variables: AddressQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "zipcode"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "zipcode",
        "variableName": "zipcode"
      }
    ],
    "concreteType": "Address",
    "kind": "LinkedField",
    "name": "address",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "street",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "neighborhood",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "city",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddressQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddressQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8cf907eeba21882323cb98098e5c10c3",
    "id": null,
    "metadata": {},
    "name": "AddressQuery",
    "operationKind": "query",
    "text": "query AddressQuery(\n  $zipcode: String!\n) {\n  address(zipcode: $zipcode) {\n    street\n    neighborhood\n    city\n    state\n  }\n}\n"
  }
};
})();

(node as any).hash = "e44247f2ab9c4dee8c9868e61c756994";

export default node;
