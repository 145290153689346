/**
 * @generated SignedSource<<2fd031d098a0f7112f67266965412ebe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type menuItems_query$data = {
  readonly cashbacks: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null | undefined;
    } | null | undefined>;
  };
  readonly companyBankAccounts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly code: string | null | undefined;
        readonly id: string;
      } | null | undefined;
    } | null | undefined>;
  };
  readonly me: {
    readonly allRoles: ReadonlyArray<string | null | undefined>;
    readonly company: {
      readonly costRevenueCenters: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
          } | null | undefined;
        } | null | undefined>;
      };
      readonly features: ReadonlyArray<string | null | undefined> | null | undefined;
      readonly hasCompanyBankAccount: boolean | null | undefined;
      readonly hasCompanyBankAccountVirtual: boolean | null | undefined;
      readonly preferences: {
        readonly cashback: {
          readonly active: boolean | null | undefined;
          readonly percentage: number | null | undefined;
        } | null | undefined;
        readonly cashbackExclusive: {
          readonly active: boolean | null | undefined;
          readonly percentage: number | null | undefined;
        } | null | undefined;
        readonly openpix: {
          readonly defaultCompanyBankAccount: {
            readonly id: string;
          } | null | undefined;
        } | null | undefined;
      };
      readonly projectType: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"subscriptions_company">;
    } | null | undefined;
    readonly id: string;
    readonly isAdmin: boolean | null | undefined;
    readonly isResponsibleByCostRevenueCenters: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"usePermission_user">;
  } | null | undefined;
  readonly " $fragmentType": "menuItems_query";
};
export type menuItems_query$key = {
  readonly " $data"?: menuItems_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"menuItems_query">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "menuItems_query"
};

(node as any).hash = "48c91843cdddae9db09693264e6e116b";

export default node;
