/**
 * @generated SignedSource<<90d45ec775c7165a5206b4720e570686>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeaderBanner_me$data = {
  readonly company: {
    readonly accountRegisters: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly status: string | null | undefined;
        } | null | undefined;
      } | null | undefined>;
    };
    readonly companyBankAccounts: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly providerFeatures: ReadonlyArray<string>;
        } | null | undefined;
      } | null | undefined>;
    };
    readonly defaultCompanyBankAccount: {
      readonly " $fragmentSpreads": FragmentRefs<"useProviderFeatures_account">;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"FeatureFlag_user">;
  readonly " $fragmentType": "HeaderBanner_me";
};
export type HeaderBanner_me$key = {
  readonly " $data"?: HeaderBanner_me$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderBanner_me">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "providerFeatures",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfoExtended",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 50,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "companyBankAccounts"
        ]
      },
      {
        "count": "first",
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "accountRegisters"
        ]
      }
    ]
  },
  "name": "HeaderBanner_me",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeatureFlag_user"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": "companyBankAccounts",
          "args": null,
          "concreteType": "CompanyBankAccountConnection",
          "kind": "LinkedField",
          "name": "__HeaderBanner_companyBankAccounts_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyBankAccountEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CompanyBankAccount",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "accountRegisters",
          "args": null,
          "concreteType": "AccountRegisterConnection",
          "kind": "LinkedField",
          "name": "__HeaderBanner_accountRegisters_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AccountRegisterEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AccountRegister",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "status",
                      "storageKey": null
                    },
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyBankAccount",
          "kind": "LinkedField",
          "name": "defaultCompanyBankAccount",
          "plural": false,
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "useProviderFeatures_account",
              "selections": [
                (v0/*: any*/)
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "4e66e9ea313f74172458eb8b456d444f";

export default node;
