import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Fragment } from 'react';
import styled from 'styled-components';
import { useWindowSize } from 'use-window-size-hook';

import Group from './Group';

import type { ReactNode } from 'react';

const StyledList = styled(List)`
  padding-top: 0;
  padding-bottom: 20px;
  transition: width 200ms ease-out;
  overflow-y: auto;
  overflow-x: hidden;

  //Styling for all Sidebar
  .Mui-selected {
    font-weight: 600;
  }
  .MuiListItemIcon-root {
    min-width: 30px;
  }
  //Style for the nested, 2nds level items
  .MuiCollapse-root .MuiListItem-root {
    padding-left: 52px;
  }
  .MuiTypography-root {
    font-weight: inherit;
  }
  .MuiList-root {
    a {
      padding-left: 52px;
    }
  }
`;

export type ItemType = {
  items?: ItemType[];
  link: string;
  label: string;
  separator?: boolean;
  newChip?: boolean;
  icon: React.ComponentType;
};

export type SidebarProps = {
  items: ItemType[];
};

const renderGroups = (items: ItemType[], isMobile: boolean) => {
  return items.map((item, index) => {
    const {
      items: groupItems,
      link,
      label,
      icon,
      separator,
      newChip,
      isTemp,
    } = item;

    if (separator) {
      return (
        <Fragment key={index}>
          <ListItem>
            <ListItemText primary={label} />
          </ListItem>
          {groupItems && renderGroups(groupItems, isMobile)}
        </Fragment>
      );
    }

    return (
      <Group
        key={index}
        label={label}
        icon={icon}
        link={link}
        items={groupItems}
        isMobile={isMobile}
        newChip={newChip}
        isTemp={isTemp}
      />
    );
  });
};

export const MenuList = ({ items }: SidebarProps): ReactNode => {
  const { width } = useWindowSize();

  const isMobile = width < 600;

  return <StyledList>{renderGroups(items, isMobile)}</StyledList>;
};
