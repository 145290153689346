/**
 * @generated SignedSource<<933d3d86408ef32f94200ffd687fd79a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeatureFlag_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"usePermission_user">;
  readonly " $fragmentType": "FeatureFlag_user";
};
export type FeatureFlag_user$key = {
  readonly " $data"?: FeatureFlag_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeatureFlag_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeatureFlag_user",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "usePermission_user",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAdmin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allRoles",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Company",
          "kind": "LinkedField",
          "name": "company",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "features",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "projectType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "b5497425b6f61355b634c94893493425";

export default node;
