/**
 * @generated SignedSource<<2d7005f8906a2786ccd0a767f2d9edbb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserMenu_me$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FeatureFlag_user" | "UserMenuAccounts_user" | "UserMenuAuths_user" | "UserMenuCompanyInfo_user" | "UserMenuProfile_user">;
  readonly " $fragmentType": "UserMenu_me";
};
export type UserMenu_me$key = {
  readonly " $data"?: UserMenu_me$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserMenu_me">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserMenu_me",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserMenuAuths_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserMenuProfile_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserMenuCompanyInfo_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserMenuAccounts_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeatureFlag_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "039052c84408251872f02c0f44169a8a";

export default node;
