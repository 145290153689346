import { CircularProgress } from '@mui/material';
import type { ButtonProps } from '@mui/material/Button';
import _Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { space, layout, flexbox, border } from 'styled-system';

import _Icon from '../icons/Icon';

import type { ReactNode } from 'react';

const Icon = styled(_Icon)`
  _font-size: 12px !important;
`;

type StyledButtonProps = {
  bgColor?: string;
};

const Button = styled(_Button)<StyledButtonProps>`
  text-transform: none;

  ${space}
  ${layout}
  ${flexbox}
  ${border}
`;

export type ActionButtonProps = {
  icon?: string;
  label?: string;
  link?: string;
  external?: boolean;
  loading?: boolean;
} & ButtonProps &
  StyledButtonProps;

const ActionButton = (
  {
    icon,
    size = 'medium',
    variant = 'contained',
    color = 'primary',
    children,
    label,
    link,
    external = false,
    loading = false,
    ...props
  }: ActionButtonProps,
): ReactNode => {
  const getLoading = () => {
    if (loading) {
      return { startIcon: <CircularProgress size={12} /> };
    }

    return {};
  };

  const innerElement = (
    <>
      {!!icon && <Icon>{icon}</Icon>}
      {label ? label : children}
    </>
  );

  if (link) {
    if (external) {
      return (
        <Button
          target='_blank'
          href={link}
          color={color}
          variant={variant}
          size={size}
          disableElevation
          {...getLoading()}
          {...props}
        >
          {innerElement}
        </Button>
      );
    }

    return (
      <Button
        component={Link}
        to={link}
        color={color}
        variant={variant}
        size={size}
        disableElevation
        {...getLoading()}
        {...props}
      >
        {innerElement}
      </Button>
    );
  }

  return (
    <Button
      color={color}
      variant={variant}
      size={size}
      disableElevation
      {...getLoading()}
      {...props}
    >
      {innerElement}
    </Button>
  );
};

export default ActionButton;
