/**
 * @generated SignedSource<<192e8e9f3549d1b96fc8e9d5c8572b14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserMasterCompanyChangeInput = {
  clientMutationId?: string | null | undefined;
  companyId: string;
};
export type UserMasterCompanyChangeMutation$variables = {
  input: UserMasterCompanyChangeInput;
};
export type UserMasterCompanyChangeMutation$data = {
  readonly UserMasterCompanyChange: {
    readonly error: string | null | undefined;
    readonly me: {
      readonly emails: ReadonlyArray<{
        readonly email: string | null | undefined;
      } | null | undefined> | null | undefined;
      readonly id: string;
      readonly name: string | null | undefined;
      readonly phoneNumbers: ReadonlyArray<string | null | undefined> | null | undefined;
      readonly taxID: {
        readonly taxID: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type UserMasterCompanyChangeMutation = {
  response: UserMasterCompanyChangeMutation$data;
  variables: UserMasterCompanyChangeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserMasterCompanyChangePayload",
    "kind": "LinkedField",
    "name": "UserMasterCompanyChange",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TaxID",
            "kind": "LinkedField",
            "name": "taxID",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "taxID",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Email",
            "kind": "LinkedField",
            "name": "emails",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phoneNumbers",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserMasterCompanyChangeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserMasterCompanyChangeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "48b4d5110237119963f630ad2f80e2e8",
    "id": null,
    "metadata": {},
    "name": "UserMasterCompanyChangeMutation",
    "operationKind": "mutation",
    "text": "mutation UserMasterCompanyChangeMutation(\n  $input: UserMasterCompanyChangeInput!\n) {\n  UserMasterCompanyChange(input: $input) {\n    error\n    me {\n      id\n      name\n      taxID {\n        taxID\n      }\n      emails {\n        email\n      }\n      phoneNumbers\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a0ca45c6867393e4c09f96af857e8f29";

export default node;
