export const config = {
  GRAPHQL_URL: process.env.GRAPHQL_URL,
  GRAPHQL_SUBSCRIPTION_URL: process.env.GRAPHQL_SUBSCRIPTION_URL,
  COMMIT_SHA: process.env.COMMIT_SHA,
  CRISP_CHAT_ID: process.env.CRISP_CHAT_ID,
  GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
  OPENPIX_LINK_URL: process.env.OPENPIX_LINK_URL,
  OPENPIX_PLUGIN_URL: process.env.OPENPIX_PLUGIN_URL,
  OPEN_PIX_APP_ID: process.env.OPEN_PIX_APP_ID,
  NODE_ENV: process.env.NODE_ENV,
  FIREBASE_VAPID: process.env.FIREBASE_VAPID,
  FIREBASE_API_KEY: process.env.FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID: process.env.FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: process.env.FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SEND_ID: process.env.FIREBASE_MESSAGING_SEND_ID,
  FIREBASE_APP_ID: process.env.FIREBASE_APP_ID,
  APP_ENV: process.env.APP_ENV,
  D4SIGN_ENVIRONMENT: process.env.D4SIGN_ENVIRONMENT,
  CLARITY_ID: process.env.CLARITY_ID,
  GA_ID: process.env.GA_ID,
  PAGAR_ME_PUBLIC_KEY: process.env.PAGAR_ME_PUBLIC_KEY,
  ENTRYPOINT: process.env.ENTRYPOINT,

  WABIZ_GLOBALID: process.env.WABIZ_GLOBALID,
  WABIZ_WEBHOOK: process.env.WABIZ_WEBHOOK,
  WABIZ_DEFAULT_URL_REDIRECT: process.env.WABIZ_DEFAULT_URL_REDIRECT,
};

export default config;
