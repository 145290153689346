/* eslint-disable @woovi/no-hex-colors */
import { createTheme, darken, lighten, alpha } from '@mui/material/styles';
import type { Theme, PaletteColor, Palette } from '@mui/material/styles';

import { hexToRgb } from '../helpers';

const hexToRGBString = (hex: string): string => hexToRgb(hex).join(',');

declare module '@mui/material/styles' {
  interface Theme {
    shape: {
      borderRadius: number;
      borderWidth: number;
    };
    sizing: {
      maxModalHeight: string;
      maxPaymentLinkWidth: string;
    };
    palette: Palette & {
      tertiary: PaletteColor;
      quaternary: PaletteColor;
    };
  }

  interface ThemeOptions {
    shape: {
      borderRadius: number;
      borderWidth: number;
    };
    sizing: {
      maxModalHeight: string;
      maxPaymentLinkWidth: string;
    };
  }
}

const primary = '#03D69D';
const secondary = '#4D4D4D';
const tertiary = '#133A6F';
const quaternary = '#607389';

const ThemeDefault = {
  darkGray: quaternary,
  lightGray: '#f8f8f8',
  highlightColor: primary,
  primaryDarkerColor: tertiary,
  primaryLighterColor: primary,
  button: {
    textColor: '#FFFFFF',
  },
  richHeaderColor1: primary,
  richHeaderColor2: '#09B889',
  richHeaderTextColor: '#FFFFFF',
};

export const getThemeMode = () => {
  const IS_SERVER = typeof window === typeof undefined;

  if (IS_SERVER) {
    return 'light';
  }

  const themeMode = localStorage.getItem('themeMode');

  if (themeMode === 'dark') {
    return 'dark';
  }

  return 'light';
};

export const ThemeMuiThemeRAW: Theme = {
  ...ThemeDefault,
  typography: {
    fontFamily: [
      'Nunito',
      '"Open Sans"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 12,
    fontWeightBold: 600,
  },

  // defining default outlined props to text field
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'normal',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: 'inherit',
          label: {
            fontWeight: theme.typography.fontWeightBold,
          },
        }),
      },
    },
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        sizeSmall: {
          height: '20px',
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        colorPrimary: {
          color: '#ff0000',
        },
        colorSecondary: {
          color: secondary,
        },
        colorDisabled: {
          color: secondary,
          opacity: 0.7,
        },
      },
    },
    Accordion: {
      styleOverrides: {
        root: {
          border: 'none',
          boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
          borderRadius: 2,
          margin: '10px 0',
          '&:before': {
            display: 'none',
          },
          '&$expanded': {
            border: 'none',
            margin: '10px 0',
          },
        },
      },
    },
    AccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: 0,
          '&$expanded': {
            minHeight: 0,
          },
        },
        content: {
          '&$expanded': {
            margin: '12px 0',
          },
          paddingRight: 32,
          '& > :last-child': {
            paddingRight: 0,
          },
        },
      },
    },
    AccordionActions: {
      styleOverrides: {
        root: {
          borderTop: `1px solid rgba(${hexToRGBString(secondary)}, 0.12)`,
          padding: '8px 4px',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontWeight: theme.typography.fontWeightRegular,
        }),
      },
    },
    MuiFab: {
      defaultProps: {
        style: {
          zIndex: 1500,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: ({ theme }) => ({
        a: {
          color: theme.palette.primary.main,
        },
      }),
    },
  },

  shape: {
    borderRadius: 4,
    borderWidth: 1,
  },
  sizing: {
    maxModalHeight: '400px',
    maxPaymentLinkWidth: '320px',
  },
};

export const ThemeMuiTheme = createTheme(ThemeMuiThemeRAW, {
  palette: {
    mode: 'light',
    primary: {
      main: primary,
      dark: primary,
      light: primary,
      contrastText: '#fff',
    },
    default: {
      main: primary,
      dark: primary,
      light: primary,
      contrastText: '#fff',
    },
    secondary: {
      main: secondary,
      dark: secondary,
      light: secondary,
      contrastText: '#fff',
    },
    tertiary: {
      main: tertiary,
      contrastText: '#fff',
      light: lighten(tertiary, 0.2),
      dark: darken(tertiary, 0.3),
    },
    quaternary: {
      main: quaternary,
      light: lighten(quaternary, 0.2),
      dark: darken(quaternary, 0.3),
    },
    background: {
      default: '#f8f8f8',
    },
  },
} as Partial<Theme>);

export const ThemeMuiDarkTheme = createTheme(ThemeMuiThemeRAW, {
  palette: {
    mode: 'dark',
    primary: {
      main: primary,
      dark: primary,
      light: primary,
      contrastText: '#fff',
    },
    secondary: {
      main: '#ededed',
      dark: '#ededed',
      light: '#ededed',
      contrastText: '#fff',
    },
    tertiary: {
      main: '#2e66b3',
      contrastText: '#fff',
      light: lighten('#2e66b3', 0.2),
      dark: darken('#2e66b3', 0.3),
    },
    quaternary: {
      main: '#7997ba',
      light: lighten('#7997ba', 0.2),
      dark: darken('#7997ba', 0.3),
    },
    default: {
      main: primary,
      dark: primary,
      light: primary,
      contrastText: '#fff',
    },
    background: {
      default: '#333',
      paper: '#454545',
    },
    text: {
      primary: '#fff',
      secondary: '#f5f5f5',
      disabled: '#e8e8e8',
    },
  },
  components: {
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.common.white,
        }),
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.common.white,
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => {
          const color = alpha(theme.palette.common.white, 0.4);

          return {
            textTransform: 'inherit',
            label: {
              fontWeight: theme.typography.fontWeightBold,
            },
            // @todo: The ideal here is to override only the disabled style,
            //        but for some reason, the overriding aren't applying any style in
            //        in the final element. So, we're overriding via root instead.
            //        Investigate better why it's happenning and fix it.
            //        After fixing, remove this "root" overriding.
            '&.Mui-disabled': {
              pointerEvents: 'visible',
              cursor: 'not-allowed',
              color,
              border: `1px solid ${color}`,
              '&:hover': {
                backgroundColor: alpha(color, 0.04),
              },
            },
          };
        },
      },
    },
  },
} satisfies Partial<Theme>);
