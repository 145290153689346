/**
 * @generated SignedSource<<c4325c2c7fadda0d57c8d21ea47584d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useCrisp_user$data = {
  readonly company: {
    readonly nameFriendly: string | null | undefined;
  } | null | undefined;
  readonly email: string | null | undefined;
  readonly id: string;
  readonly name: string | null | undefined;
  readonly phoneNumbers: ReadonlyArray<string | null | undefined> | null | undefined;
  readonly " $fragmentType": "useCrisp_user";
};
export type useCrisp_user$key = {
  readonly " $data"?: useCrisp_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCrisp_user">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useCrisp_user"
};

(node as any).hash = "5b435b9d751754cc2e3c1eef9735cc7d";

export default node;
