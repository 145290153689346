/**
 * @generated SignedSource<<22f4a3a1088504ff97c8531b70ea56ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MenuRightIcons_query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"HeaderBanner_me" | "HeaderButtonTheme_user" | "QuickActionsModal_me" | "User_me">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"Notification_query" | "QuickActionsModal_query">;
  readonly " $fragmentType": "MenuRightIcons_query";
};
export type MenuRightIcons_query$key = {
  readonly " $data"?: MenuRightIcons_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"MenuRightIcons_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MenuRightIcons_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Notification_query"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuickActionsModal_query"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HeaderBanner_me"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "User_me"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "QuickActionsModal_me"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HeaderButtonTheme_user"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "c9e99821cb995eed0d198ffb3f238f96";

export default node;
